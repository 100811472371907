import request from '@/utils/request.js'
const api = {
  dictData: '/sys/dictdata'
}
export function getDictData(params) {
  return new Promise((resolve, reject) => {
    request.get(api.dictData, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
