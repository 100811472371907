import request from '@/utils/request'

// api地址
const api = {
  refund: '/frm/activityCost/refund',
  refundBatch: '/frm/activityCost/refund/batch',
  page: '/frm/activityCost/page',
  export: '/frm/activityCost/export/'
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id退款
 * @param id id
 * @returns {Promise<>}
 */
export function refundById(id) {
  return new Promise((resolve, reject) => {
    request.post(api.refund, { orderNumber: id }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量退款
 * @param id id
 * @returns {Promise<>}
 */
export function refundBatch(data) {
  return new Promise((resolve, reject) => {
    request.post(api.refundBatch, { orderNumbers: data }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 导出活动费用
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function exportActivityCost(id, params) {
  return new Promise((resolve, reject) => {
    request.get(api.export + id, {
      params: params,
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}
