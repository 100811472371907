<template>
  <a-card :bordered="false">
    <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
      <a-row>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="姓名:">
            <a-input v-model:value.trim="where.name" placeholder="请输入姓名" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="手机号:">
            <a-input v-model:value.trim="where.phone" placeholder="请输入手机号" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="付款状态:">
            <a-select v-model:value="where.payState" placeholder="请选择付款状态" allow-clear>
              <a-select-option v-for="item in payStateList" :key="item.dictDataCode" :value="item.dictDataCode">
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div>
      <ud-pro-table ref="tableReg" row-key="regLogId" :datasource="datasource" :columns="columns"
        v-model:selection="selection" :scroll="{ x: 'max-content' }"  @change="onTableChange">
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="exportActivityCost">
              <span>导出</span>
            </a-button>
            <a-button type="primary" danger @click="refundBatch">
              <span>批量退款</span>
            </a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a-popconfirm title="确定要退款吗？" @confirm="refund(record)" v-if="record.payState === '已付款'">
              <a class="ud-text-danger">退款</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </div>
  </a-card>
</template>

<script>
import * as activityCostApi from '@/api/activity/activityCost'
import * as dictApi from '@/api/dict/dictData.js'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { toDateString } from '@/utils/util.js'

export default {
  name: 'cost-index',
  emits: ['done', 'update:visible'],
  props: {
    activityId: Object
  },
  watch: {
    activityId() {
      if (this.activityId) {
        this.where = {}
        this.$nextTick(() => {
          this.reload()
        })
      }
    }
  },
  data() {
    return {
      name: 'grouptList',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableReg.tableIndex + index
        },
        {
          title: '姓名',
          dataIndex: 'name',
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'payState',
          sorter: true
        },
        {
          title: '付款金额',
          dataIndex: 'payPrice',
          sorter: true
        },
        {
          title: '付款时间',
          dataIndex: 'payTime',
          sorter: true
        },
        {
          title: '退款金额',
          dataIndex: 'refundPrice',
          sorter: true
        },
        {
          title: '退款时间',
          dataIndex: 'refundTime',
          sorter: true
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      regStateList: [],
      payStateList: []
    }
  },
  mounted() {
    this.queryRegState()
    this.queryPayState()
  },
  methods: {
    datasource(option, callback) {
      activityCostApi
        .page({
          ...this.where,
          ...this.order,
          activityId: this.activityId,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.tableReg.reload({ page: 1 })
    },
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 查询报名状态
    queryRegState() {
      dictApi
        .getDictData({ dictCode: 'applyState' })
        .then((res) => {
          if (res.code === 0) {
            this.regStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询支付状态
    queryPayState() {
      dictApi
        .getDictData({ dictCode: 'payState' })
        .then((res) => {
          if (res.code === 0) {
            this.payStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 删除单个 */
    refund(row) {
      const hide = this.$message.loading('请求中..', 0)
      activityCostApi.refundById(row.outTradeNo).then(res => {
        hide()
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.reload()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    },
    /* 批量删除 */
    refundBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要退款选中的数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = this.selection.map((d) => d.outTradeNo).join(',')
          activityCostApi.refundBatch(data).then(res => {
            hide()
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
            } else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            hide()
            this.$message.error(e.msg)
          })
        }
      })
    },
    exportActivityCost() {
      activityCostApi
        .exportActivityCost(this.activityId, {
          ...this.where
        })
        .then((res) => {
          const { data, headers } = res
          const fileName = '活动费用-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => {
        })
    }

  }
}
</script>
<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}

:deep(.ud-tool) {
  display: none;
}
</style>
